<template>
  <div>
    <div v-if="current != null">
      <EventForm v-model="current" ref="form" />
    </div>
    <vca-card
      class="event-form-error"
      v-if="invalidFields.length > 0"
      v-html="errorMessages"
    ></vca-card>
    <!--
      1. update()
      state egal
      validate
     -->
    <button class="vca-button" @click.prevent="update()">
      <span v-if="!current.isPublished">
        {{ $t("context_button.save", { 0: $t("context.draft") }) }}
      </span>
      <span
        v-else-if="(!isCanceled && !isFinished) || this.hasSystemPermission()"
      >
        {{ $t("context_button.update", { 0: $t("context.event") }) }}
      </span>
    </button>
    <div v-if="!current.isPublished">
      <button
        v-if="!isCanceled"
        class="vca-button-small"
        @click.prevent="publish()"
      >
        {{ $t("button.publish") }}
      </button>
    </div>
    <button v-if="isCanceled" class="vca-button-small" @click.prevent="draft()">
      {{ $t("context_button.save", { 0: $t("context.as_draft") }) }}
    </button>

    <div class="vca-center" v-if="!isCanceled && !isFinished">
      <vca-cancel-button
        @click="doCancel()"
        :placeholder="$t('button.cancelling')"
      />
    </div>
    <vca-card class="vca-center" v-if="canDelete">
      <vca-cancel-button
        @click="doDelete()"
        :placeholder="$t('button.delete')"
      />
    </vca-card>
    <vca-card class="vca-center" v-if="hasSystemPermission('admin')">
      <button class="vca-button-small" @click="doSync()">
        {{ $t("button.sync") }}
      </button>
    </vca-card>
  </div>
</template>

<script>
import EventForm from "@/components/events/form/EventForm";
import { mapGetters } from "vuex";
export default {
  name: "EventEdit",
  components: { EventForm },
  data() {
    return {
      invalidFields: [],
    };
  },
  methods: {
    doSync() {
      if (!confirm(this.$t("events.sync.confirm"))) {
        return false;
      }
      this.$store.dispatch("events/sync");
    },
    validate() {
      this.$refs.form.validate();
      this.invalidFields = Object.keys(this.$v.current.$params).filter(
        (fieldName) => this.$v.current[fieldName].$invalid
      );
    },
    prepareSave() {
      if (this.hasSystemPermission()) {
        this.current.event_state.internal_confirmation = this.user.id;
      } else if (this.hasPoolPermission(this.poolEventPermissions)) {
        this.current.event_state.crew_confirmation = this.user.id;
        this.current.crew.id = this.user.crew.crew_id;
        this.current.crew.name = this.user.crew.name;
        this.current.crew.email = this.user.crew.email;
      }
      if (this.current.type_of_event == "crew_meeting") {
        this.current.application.start_date = parseInt(Date.now() / 1000);
        this.current.application.end_date = this.current.start_at;
        this.current.application.supporter_count = 999;
      }
    },
    publish() {
      if (this.isInvalid) {
        this.validate();
      } else {
        this.validate();
        if (!confirm(this.$t("events.messages.publish"))) {
          return false;
        }
        this.invalidFields = [];
        this.prepareSave();
        this.current.event_state.state = "published";
        this.$store.dispatch({ type: "events/update" });
      }
    },
    update() {
      if (this.$v.current.type_of_event.$invalid) {
        this.invalidFields = ["type_of_event"];
        this.$refs.form.validate_type();
      } else if (
        this.isInvalid &&
        !["created", "canceled"].includes(this.current.event_state.state)
      ) {
        this.validate();
      } else {
        if (
          this.current.isPublished &&
          !confirm(this.$t("events.messages.update"))
        ) {
          return false;
        }
        this.validate();
        this.invalidFields = [];
        this.$store.dispatch({ type: "events/update" });
      }
    },
    doCancel() {
      if (!confirm(this.$t("events.messages.cancel"))) {
        return false;
      }
      var log = this.current.event_state.state;
      this.current.event_state.state = "canceled";
      this.$store.dispatch({ type: "events/update" }).catch(() => {
        this.current.event_state.state = log;
      });
    },
    doDelete() {
      if (!confirm(this.$t("events.messages.delete"))) {
        return false;
      }
      this.$store
        .dispatch({
          type: "events/delete",
          data: this.current.id,
        })
        .then(() => {
          this.$router.push({ path: "/events" });
        });
    },
    draft() {
      if (!confirm(this.$t("events.messages.draft"))) {
        return false;
      }
      this.current.event_state.state = "created";
      this.$store.dispatch({ type: "events/update" });
    },
  },
  validations() {
    return this.validation;
  },
  computed: {
    canDelete() {
      return (
        this.hasSystemPermission() ||
        (this.hasPoolPermission(this.poolEventPermissions) &&
          this.isNotPublished)
      );
    },
    errorMessages() {
      return this.invalidFields
        .map((el) => this.$t("events." + el + ".errorMsg"))
        .join("<br/>");
    },
    isCanceled() {
      return (
        this.current.event_state && this.current.event_state.state == "canceled"
      );
    },
    isNotPublished() {
      return (
        this.current.event_state && this.current.event_state.state == "created"
      );
    },
    isFinished() {
      return (
        this.current.event_state &&
        (this.current.event_state.state == "finished" ||
          this.current.event_state.state == "closed")
      );
    },
    createdWithoutCrew() {
      return (
        !this.hasCrew &&
        this.current.event_state &&
        this.current.event_state.state == "created"
      );
    },
    hasRequestedToOwnCrew() {
      return (
        this.hasCrew &&
        this.current.crew &&
        this.user.crew.crew_id == this.current.crew.id &&
        !this.current.isPublished
      );
    },
    hasCrew() {
      return this.current.crew_id != "";
    },
    current: {
      set(value) {
        this.$store.commit("events/current", value);
      },
      get() {
        return this.$store.state.events.current;
      },
    },
    isInvalid() {
      return this.$v.current.$invalid;
    },
    ...mapGetters({
      validation: "events/validations",
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
};
</script>
