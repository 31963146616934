<template>
  <vca-card>
    <h2>{{ $t("events.edit.header") }}</h2>
    <EventEdit />
  </vca-card>
</template>

<script>
import EventEdit from "@/components/events/EventEdit";
import { mapGetters } from "vuex";
export default {
  name: "EventsEdit",
  components: { EventEdit },
  created() {
    this.$store.commit("events/current", { id: this.$route.params.id });
    this.$store
      .dispatch("events/get")
      .then(() => {
        if (
          this.isEventAsp ||
          (this.hasPoolPermission(this.poolEventPermissions) &&
            this.event.crew_id == this.user.crew.crew_id) ||
          this.hasSystemPermission()
        ) {
          if (this.hasSystemPermission()) {
            this.$store.dispatch({ type: "crews/public" });
          }
          this.$store.dispatch({ type: "organisations/list" });
          this.$store.dispatch({ type: "artists/list" });
          this.$store.dispatch({ type: "organizers/list" });
          const breadCrumb =
            '<a href="' +
            window.location.href +
            '" title="' +
            this.$t("events.popup.edit", {
              0: "'" + this.event.name + "'",
            }) +
            '">' +
            this.$t("events.popup.edit", {
              0: "'" + this.event.name + "'",
            }) +
            "</a>";
          this.$store.commit("breadCrumb", breadCrumb);
        }
      })
      .catch((error) => {
        if (error.response.status == 404) {
          this.notification({
            title: this.$t("messages.title.error"),
            body: this.$t("messages.error.page_404"),
            type: "error",
          });
          this.$router.push({ path: "/events" });
        }
      });
  },
  computed: {
    ...mapGetters({
      event: "events/current",
      user: "user/current",
      poolEventPermissions: "user/roles/poolEventPermissions",
      hasPoolPermission: "user/roles/hasPoolPermission",
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
    isEventAsp() {
      return (
        this.user &&
        this.event.event_asp_id &&
        this.user.id == this.event.event_asp_id
      );
    },
  },
};
</script>
